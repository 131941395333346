body.font-Playfair .font-primary {
    font-family: 'Playfair Display SC', serif;
    text-transform: none;
    font-weight: 400;
}

body.font-Playfair #hero .hero-overlay {
    text-transform: none;
}

body.font-Playfair .btn {
    font-size: 15px;
}

body.font-Playfair .btn-xl {
    font-size: 16px;
}

body.font-Playfair .btn-xlg {
    font-size: 18px;
}

body.font-Playfair .btn-super {
    font-size: 17px;
}

body.font-Playfair .btn-sm {
    font-size: 15px;
}


body.font-Playfair .staff-member .staff-photo .staff-name {
    font-size: 16px;
}

body.font-Playfair .section-title,
body.font-Playfair .page-title-headings h2,
body.font-Playfair .page-title-headings h1,
body.font-Playfair .locations-section .dealer-header,
body.font-Playfair .vehicle-title,
body.font-Playfair .vehicle-card .vehicle-title,
body.font-Playfair .site-logo h2,
body.font-Playfair .hero-overlay h2,
body.font-Playfair .section-title h2,
body.font-Playfair .dealer-name,
body.font-Playfair .modal-title,
body.font-Playfair .staff-member .staff-photo .staff-name,
body.font-Playfair .staff-member .staff-photo .staff-name span,
body.font-Playfair .header-logo a {
    text-transform: none;
    letter-spacing: 0.05em;
    font-weight: 400 !important;
}
