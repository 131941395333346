/* SEONamedLinks ===========================================================*/
.seonamedlinks-standard .section-heading {
    padding-bottom: 20px;
}

.seonamedlinks-standard .section-title {
    text-align: center;
}

.seonamedlinks-standard .column ul li {
    list-style: none;
    margin-bottom: 14px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
}

.seonamedlinks-standard .count {
    font-size: 12px;
}

.seonamedlinks-standard .column {
    -webkit-column-width: 220px;
    -moz-column-width: 220px;
    column-width: 220px;
}

@media screen and (max-width: 767px) {
    .seonamedlinks-standard {
        padding-left: 1em;
        padding-right: 1em;
    }
}


