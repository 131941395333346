.h-find-m1 {
    padding: 50px 0;
}

.h-find-m1 .section-title {
	text-align: center;
}

.h-find-m1 label { /*sr-only css*/
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@media (min-width: 768px) {
    .h-find-m1 {
        padding: 50px 20px;
    }
}

@media (max-width: 991px) {
    .h-find-m1 label {
        display: block;
    }
}

@media (max-width: 767px) {
    .h-find-m1 {
        padding: 25px 15px;
    }
}