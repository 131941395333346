/*===================================================================================================
  Contact Us
===================================================================================================*/

.contact-form {
    padding: 20px 0 60px;
    overflow: hidden;
}

.contact-m1 .dealership-photo {
    width: 100%;
    margin-bottom: 25px;
}

.contact-m1 .contact-card {
    padding: 15px;
    margin-bottom: 25px;
}

.contact-m1 .radio-block-label {
    display: block;
}

.contact-m1 .checkbox-inline, 
.contact-m1 .radio-inline {
    padding-left: 25px;
    margin-bottom: 10px;
}

.contact-m1 .dealer-info-card {
    padding: 0 30px;
}

@media screen and (max-width: 991px) {
    .contact-m1 .contact-form {
        margin-top: 25px;
    }

    .contact-m1 .subhead-title {
        margin-top: 0;
    }
}

@media(max-width: 767px) {
    .contact-m1 .wedge {
        display: none;
    }
}


/* Business Hours
===========================================================*/

.contact-m1 .inventory-info {
    padding: 15px 0 5px 0;
}

.contact-m1 .inventory-info .c-table {
    margin-bottom: 8px;
}

.contact-m1 .inventory-info .c-tablecell {
    text-align: right;
    border-bottom: 1px solid #ddd;
    padding-bottom: 8px;
}

.contact-m1 .inventory-info .c-tablecell:first-of-type {
    font-weight: 700;
    text-align: left;
}
