.header-m1 {
    padding: 15px;
    text-align: center;
}

.header-m1 .header-logo {
    font-size: 30px;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 0;
	font-weight: 700;
}

.header-m1 .header-span {
	display: none;
}

.header-m1 .header-logo a:hover {
    text-decoration: none;
}

.header-m1 .header-phone {
	font-weight: 400;
	font-size: 22px;
}


.header-m1 .img-logo {
    display: block;
    max-width: 600px;
    max-height: 150px;
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.header-m1 .header-table {
    margin: 0 auto;
    float: none;
    display: initial;
    display: table;
    width: 100%;
}

.header-m1 .header-item {
	display: table-cell;
  	vertical-align: middle;
	float: none;
}

.header-m1 address {
	margin-bottom: 0;
	margin-top: 10px;
	font-size: 14px;
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    text-transform: none;
}

.header-m1 .header-phone i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 2px;
  opacity: .7; 
}

.header-m1 .header-name {
	font-size: 18px;
}

@media(max-width: 991px) and (min-width: 768px) {
    .header-m1 .header-table {
        display: block;
    }

    .header-m1 .header-item {
        display: block;
        width: 100%;
    }
}

@media(max-width:767px) {
    .header {
        display: none;
    }
}