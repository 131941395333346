
/*===================================================================================================
  About Us Section
===================================================================================================*/

.h-about-m1 {
    padding: 0;
}

.h-about-m1 .spotlight {
    -moz-align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    align-items: center;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
}

.h-about-m1 .spotlight .image {
    -moz-order: 1;
    -webkit-order: 1;
    -ms-order: 1;
    order: 1;
    border-radius: 0;
    width: 50%;
}

.h-about-m1 .spotlight .image img {
    border-radius: 0;
    width: 100%;
}

.h-about-m1 .spotlight .content {
    padding: 15px 4em 15px 4em;
    -moz-order: 2;
    -webkit-order: 2;
    -ms-order: 2;
    order: 2;
    width: 50%;
    line-height: 1.7em;
	text-align: center;
}

.h-about-m1 .spotlight .content .section-heading {
	padding-bottom: 10px;
}

.h-about-m1 .spotlight .content .about-text {
	line-height: 1.8em;
	font-size: 13px;
	margin-bottom: 25px;
    height: 134px;
    display: -webkit-box;
    max-width: 4400px;
    -webkit-line-clamp:6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media screen and (max-width: 1280px) {
    .h-about-m1 .spotlight .image {
        width: 50%;
    }

    .h-about-m1 .spotlight .content {
        width: 50%;
    }
}

@media screen and (max-width: 980px) {
    .h-about-m1 .spotlight {
        display: block;
    }

    .h-about-m1 .spotlight .image {
        width: 100%;
		border-right: 0;
    }

    .h-about-m1 .spotlight .content {
        padding: 3em;
        max-width: none;
        width: 100%;
    }
}

@media screen and (max-width: 736px) {
    .h-about-m1 .spotlight .content {
        padding: 1.7em 2em 3.5em 2em;
    }
}
