

/* Price ===========================================================*/

.price {
    padding: 4em 0 5em 0;
}

    .price .section-heading {
        padding-bottom: 20px;
    }

    .price .section-title {
        text-align: center;
    }

    .price .column ul li {
        list-style: none;
        margin-bottom: 14px;
        font-size: 16px;
        cursor: pointer;
        text-align: center;
    }

    .price .count {
        font-size: 12px;
    }

    .price .column {
        -webkit-column-width: 220px;
        -moz-column-width: 220px;
        column-width: 220px;
    }

@media screen and (max-width: 767px) {
    .price {
        padding-left: 1em;
        padding-right: 1em;
    }
}
/* Colors =====*/
.price {
    background-color: #0083ae;
    color: #fff;
}

.price .section-title {
    color: #fff;
}

    .price .section-title:after {
        background: #fff;
    }

.price a {
    color: #fff;
}

.price a:hover {
    color: #e5e5e5;
}

.price .count {
    color: inherit;
}
