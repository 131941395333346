.welcome {
	margin-top: 0;
	padding: 0;
	min-height: 200px;
}

.welcome .header-m1 {
	text-align: center;
	padding: 150px 0;
	z-index: 4;
	position: relative;
}

.welcome .header-m1 .header-section {
	font-size: 40px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 25px;
}

.welcome .header-m1 .header-logo {
	font-size: 40px;
	color: #ffffff;
	font-weight: 700;
	margin-bottom: 10px;
}

.welcome .header-m1 .header-span {
	font-weight: 400;
	display: block;
	font-size: 17px;
	font-family: "Open Sans", Helvetica, Arial, sans-serif;
}

.welcome .header-m1 .header-logo a {
    pointer-events: none;
	cursor: default;
}

.welcome .header-m1 .header-phone {
	display: none;
}

.welcome .header-m1 .welcome-btn {
	display: inline-block;
}